import React from 'react';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TextField, Box, styled } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Configuring dayjs plugins
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);
dayjs.extend(localizedFormat);
dayjs.extend(isBetween);

const StyledBox = styled(Box)({
  width: '100%',
});

const Datetime = ({ field, value, onChange }) => {
  const handleDateChange = (newValue) => {
    onChange(field.fieldName, newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledBox>
        <DateTimePicker
          sx={{ width: "100%" }}

          onChange={handleDateChange}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </StyledBox>
    </LocalizationProvider>
  );
};

export default Datetime;
