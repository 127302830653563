import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation, Link } from "react-router-dom";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from '@mui/icons-material/Circle';
import * as Icons from '@mui/icons-material';
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const SidebarMenu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const location = useLocation(); // Hook per ottenere la rotta corrente

  useEffect(() => {
    const fetchMenuItems = async () => {
      const headers = {
        Authorization: "Bearer " + localStorage.getItem("token"),
      };

      try {
        const response = await axios.get(
          process.env.REACT_APP_APP_ENDPOINT + "api/auth/getSidebar",
          { headers: headers }
        );
        setMenuItems(response.data);
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };

    fetchMenuItems();
  }, []);

  // Espandi l'accordion corrispondente alla rotta corrente
  useEffect(() => {
    menuItems.forEach(item => {
      if (item.children && item.children.some(child => location.pathname === child.relatedObject)) {
        setExpanded(item.id);
      }
    });
  }, [location.pathname, menuItems]);

  const handleAccordionChange = (id) => (event, isExpanded) => {
    setExpanded(isExpanded ? id : false);
  };

  const iconComponents = Icons;

  const renderMenuItems = (items) => {
    return items.map((item) => {
      const Icon = item.icon && Icons[item.icon] ? Icons[item.icon] : CircleIcon;
      const IconToRender = iconComponents[item.icon];
      const isSelected = location.pathname === item.relatedObject;

      if (item.children && item.children.length > 0) {
        return (
          <Accordion
            key={item.id}
            disableGutters
            elevation={0}
            square
            sx={{ bgcolor: "#BCE7FD", borderBottom: "2px solid #ffcb00" }}
            expanded={true} // Apre l'Accordion se è selezionato
            onChange={handleAccordionChange(item.id)} // Gestisce il cambio di stato dell'Accordion
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              {IconToRender ? React.createElement(IconToRender, { sx: { fontSize: "25px", marginRight: "10px", color: "black" } }) : null}
              <Typography color="#004057">{item.label}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ background: "white", p: 0 }}>
              <List component="div" disablePadding>
                {renderMenuItems(item.children)}
              </List>
            </AccordionDetails>
          </Accordion>
        );
      } else {
        const isExternal = item.type === 'externalButton';
        if (isExternal) {
          return (
            <a
              href={item.path || "#"}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }} // Removing default anchor styling
              key={item.id}
            >
              <ListItemButton>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {IconToRender ? React.createElement(IconToRender, { sx: { fontSize: "25px", marginRight: "10px", color: "white" } }) : null}
                  <Typography sx={{ pl: 0, color: "white" }}>
                    {item.label}
                  </Typography>
                  <OpenInNewIcon sx={{ ml: 2, fontSize: 15, marginRight: "10px", color: "white" }} />
                </Box>
              </ListItemButton>
            </a>
          );
        } else {
          return (
            <ListItemButton
              key={item.id}
              component={Link}
              to={item.relatedObject || "#"}
              sx={{
                borderBottom: "1px solid lightgrey",
                backgroundColor: isSelected ? '#004057' : 'inherit' // Evidenzia l'elemento selezionato
              }}
              selected={isSelected} // Imposta l'elemento come selezionato
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {IconToRender ? React.createElement(IconToRender, { sx: { fontSize: "25px", marginRight: "10px", color: isSelected ? 'black' : 'black' } }) : null}
                <Typography sx={{ pl: 1, color: isSelected ? 'black' : 'black' }}>
                  {item.label}
                </Typography>
              </Box>
            </ListItemButton>
          );
        }
      }
    });
  };

  return <List sx={{ color: '#ffffff' }}>{renderMenuItems(menuItems)}</List>;
};

export default SidebarMenu;
