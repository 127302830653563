import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Grid, Paper, Typography, Accordion, AccordionSummary, AccordionDetails, IconButton, InputLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import FieldsRenderer from 'components/fields-renderer';

const OneToMany = ({ objectName, value, onChange }) => {
    const [fields, setFields] = useState([]);
    const [items, setItems] = useState(value || []);

    useEffect(() => {
        const fetchFields = async () => {
            try {
                const endpoint = `https://erpbackend.kpsfactory.datadomus.com/public/api/auth/getFieldsForObjectTable/${objectName}`;
                const response = await axios.get(endpoint, {
                    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
                });

                setFields(response.data);
            } catch (error) {
                console.error('Errore nella chiamata all\'endpoint:', error);
            }
        };

        fetchFields();
    }, [objectName]);

    const handleAddItem = () => {
        setItems([...items, {}]);
    };

    const handleRemoveItem = (index) => {
        const newItems = items.filter((item, idx) => idx !== index);
        setItems(newItems);
        onChange(objectName, newItems);
    };

    const handleItemChange = (index, fieldName, newValue) => {
        const newItems = items.map((item, idx) =>
            idx === index ? { ...item, [fieldName]: newValue } : item
        );
        setItems(newItems);
        onChange(objectName, newItems);
    };

    return (
        <>
            {items.map((item, index) => (
                <Accordion key={index}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                    >
                        <Typography>{`Prodotto ${index + 1}`}</Typography>
                        <IconButton
                            onClick={() => handleRemoveItem(index)}
                            edge="end"
                            aria-label="delete"
                            size="small"
                            color="secondary"
                        >
                            <DeleteIcon />
                        </IconButton>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {fields.map((field) => {
                                const isReadOnly = Boolean(field.isReadOnly);

                                if (isReadOnly || (field.fieldName == "created_at" || field.fieldName == "updated_at")) {
                                    return null;
                                }

                                return (
                                    <Grid item xs={12} sm={12} key={field.id}>
                                        <InputLabel htmlFor={field.name} sx={{ fontWeight: "bold" }}>
                                            {field.label}
                                            {field.required && (
                                                <span style={{ color: "red" }}> *</span>
                                            )}
                                        </InputLabel>
                                        <FieldsRenderer
                                            field={field}
                                            value={item[field.fieldName]}
                                            onChange={(fieldName, newValue) => handleItemChange(index, fieldName, newValue)}
                                        />
                                    </Grid>
                                );
                            })}
                        </Grid>

                    </AccordionDetails>
                </Accordion>
            ))}
            <Button variant="contained" color="primary" onClick={handleAddItem} style={{ marginTop: '16px' }}>
                Aggiungi Prodotto
            </Button>
        </>
    );
};

export default OneToMany;
