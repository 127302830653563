import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import ProtectedRoute from "./routes/ProtectedRoute";
import PageKanBan from "./components/layout/PageKanBan";
import PageGantt from "./components/layout/PageGantt";
import Dashboard from "./pages/Dashboard";
import ShowAsta from "./pages/Aste/showAsta";
import ShowAstaPrivate from "./pages/Aste/showAstaPrivate";
import LeMieInserzioni from "./pages/Inserzioni/LeMieInserzioni";
import { Layout } from "./components/layout/Layout";
import Login from "./pages/Login";
import Register from "./pages/Register";
import TutteLeAstePage from "./pages/Aste/tutteLeAste";
import Wallet from "./pages/wallet/Wallet";
import List from "./pages/Adminlist/List";
import Show from "./pages/Adminlist/Show";
import Impostazioni from "./pages/Impostazioni/Impostazioni";
import MyProfile from "./pages/Impostazioni/Impostazioni";
import IlMioProfilo from "./pages/Profilo/IlMioProfilo";
import MyCustomers from "./pages/Impostazioni/Impostazioni";
import env from "react-dotenv";
import { LicenseInfo } from "@mui/x-license-pro";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Calendar from "components/calendar";
import NotFoundPage from "pages/NotFoundPage";
import ComingSoonPage from "pages/ComingSoon";
import { GoogleMapsProvider } from './components/layout/GoogleMapsContext';
import DashboardEmail from "pages/DashboardEmail";

LicenseInfo.setLicenseKey(
  "792d60123edeb1405c178f2f86f07ae7Tz04NzI0OCxFPTE3NDMwOTAxMzcwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const token = localStorage.getItem("token");
  useEffect(() => {
    document.title = "K CRM - Customer Management Relationship by KPS Factory";
  }, []);
  return (
    <>
      <GoogleMapsProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={true}
        />

        <BrowserRouter>
          <Routes>
            <Route path="/coming-soon" element={<ComingSoonPage />} />
            <Route element={<ProtectedRoute />}>
              <Route
                path="/dashboard"
                element={
                  <Layout>
                    <Dashboard />
                  </Layout>
                }
              />

              <Route
                path="/dashboardEmails"
                element={
                  <Layout>
                    <DashboardEmail />
                  </Layout>
                }
              />

              <Route index element={<Login />} />
              <Route path="/register" element={<Register />} />
              <Route
                path="/mio-profilo"
                element={
                  <Layout>
                    <IlMioProfilo />
                  </Layout>
                }
              />

              <Route
                path="/adminlist/my-customers"
                element={
                  <Layout>
                    <MyCustomers />
                  </Layout>
                }
              />

              <Route
                path="/list/opportunities"
                element={
                  <Layout>
                    <PageKanBan />
                  </Layout>
                }
              />

              <Route
                path="/list/project/:projectId/gantt"
                element={
                  <Layout>
                    <PageGantt />
                  </Layout>
                }
              />
              <Route
                path="/le-mie-inserzioni"
                element={
                  <Layout>
                    <LeMieInserzioni />
                  </Layout>
                }
              />

              <Route
                path="/tutte-le-aste"
                element={
                  <Layout>
                    <TutteLeAstePage />
                  </Layout>
                }
              />
              <Route
                path="/wallet"
                element={
                  <Layout>
                    <Wallet />
                  </Layout>
                }
              />
              <Route
                path="/admin/calendario"
                element={
                  <Layout>
                    <Calendar />
                  </Layout>
                }
              />
              <Route
                path="/list/:object"
                element={
                  <Layout>
                    <PageKanBan />
                  </Layout>
                }
              />

              <Route
                path="/list/:object/filter/:fieldName/:fieldValue"
                element={
                  <Layout>
                    <PageKanBan />
                  </Layout>
                }
              />

              <Route
                path="/list/:object/:recordId/related/:fieldName"
                element={
                  <Layout>
                    <List />
                  </Layout>
                }
              />

              <Route
                path="/adminlist/:object/show/:id"
                element={
                  <Layout>
                    <Show />
                  </Layout>
                }
              />
              <Route
                path="/list/:object/show/:id"
                element={
                  <Layout>
                    <ShowAstaPrivate />
                  </Layout>
                }
              />
              <Route
                path="/settings"
                element={
                  <Layout>
                    <Impostazioni />
                  </Layout>
                }
              />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </GoogleMapsProvider>
    </>
  );
}

export default App;
