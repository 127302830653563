import React, { useState, useEffect } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const StyledDropzoneContainer = styled.div`
  margin-top: 20px;
  .MuiDropzoneArea-root {
    background-color: #f0f8ff; // Colore di sfondo di base leggermente azzurro
    transition: background-color 0.3s ease; // Transizione per l'effetto hover
    min-height: 100px;
    margin-bottom: 20px;
    &:hover {
      background-color: #e0f7fa; // Cambia il colore di sfondo al passaggio del mouse
      border-color: #82b1ff; // Cambia il colore del bordo al passaggio del mouse
    }
  }
`;

const dropzoneStyles = {
  icon: {
    color: "green",
    width: "51px!important",
    height: "51px!important",
  },
};

const DropzoneAreaExample = ({ relationId, onUpload }) => {
  const [files, setFiles] = useState([]); // Stato per gestire i file
  const [currentRecordId, setCurrentRecordId] = useState(null);
  const general = useSelector((state) => state.general); // Redux state
  const location = useLocation(); // Router per ottenere l'ID dal pathname

  useEffect(() => {
    const recordId = location.pathname.split("/")[4]; // Estrai l'ID dal percorso dell'URL
    setCurrentRecordId(recordId);
  }, [location]);

  const handleChange = (newFiles) => {
    if (newFiles.length > 0) {
      setFiles([newFiles[newFiles.length - 1]]); // Imposta solo l'ultimo file selezionato
    }
  };

  useEffect(() => {
    if (files.length > 0) {
      convertBlobToBase64();
    }
  }, [files]);

  const convertBlobToBase64 = () => {
    if (files.length === 0) {
      return;
    }

    const file = files[0]; // Prendi solo l'ultimo file caricato
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      callMockApiEndpoint(base64String, file.name); // Invia il file corrente
    };
    reader.readAsDataURL(file);
  };

  const callMockApiEndpoint = (base64String, fileName) => {
    const endpoint = process.env.REACT_APP_APP_ENDPOINT;
    const mockApiEndpoint = `${endpoint}api/auth/storeRecord/file`;
    const token = localStorage.getItem("token");

    axios
      .post(
        mockApiEndpoint,
        {
          file: base64String,
          title: fileName, // Nome del file
          related: relationId + ":" + currentRecordId, // Relazione
          owner_id: general.userId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("Mock API Response:", response.data);
        onUpload && onUpload(); // Se c'è una callback onUpload, chiamala
        toast.success("File caricato con successo!");
        setFiles([]); // Resetta i file dopo il caricamento
      })
      .catch((error) => {
        console.error("Error during API call:", error);
        toast.error("Errore durante il caricamento del file.");
        setFiles([]); // Resetta i file anche in caso di errore
      });
  };

  return (
    <StyledDropzoneContainer>
      <DropzoneArea
        onChange={handleChange}
        acceptedFiles={["image/*", "application/pdf"]}
        maxFileSize={5000000}
        filesLimit={5}
        dropzoneText="Clicca o trascina i file qui"
        showPreviewsInDropzone={false}
        showPreviews={false}
        showAlerts={true}
        showFileNamesInPreview={true}
        classes={dropzoneStyles}
      />
    </StyledDropzoneContainer>
  );
};

export default DropzoneAreaExample;
