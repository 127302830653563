import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Grid,
  Tooltip,
  Typography,
  CardActions,
  Button,
  Avatar,
  CardMedia,
  ButtonGroup,
  Box,
  CardHeader,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import {
  Edit as EditIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import Skeleton from "@mui/material/Skeleton";
import Drawer from "@mui/material/Drawer";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import SimpleDynamicForm from "../../components/simpleDynamicform";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import DynamicFormComponentModal from "../../components/modalDynamicform";
import CommentSection from "components/Comment";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PhoneIcon from "@mui/icons-material/Phone";
import toast from "react-hot-toast";
import Calendar from "components/calendar";
import EmailButton from "components/EmailButton";
import MasterDetailTable from "./MasterDetailComponents/MasterDetailTable";
import FileMasterDetail from "./MasterDetailComponents/FileMasterDetail";
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useGoogleMaps } from '../../components/layout/GoogleMapsContext';

const Show = ({ fields, values, prefilledValues }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const dynamicFieldValueState = useSelector((state) => state.dynamicObjects);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValues, setFormValues] = useState(values || {});
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [formData, setFormData] = useState(prefilledValues || {});
  const [fieldsData, setFieldsData] = useState({});
  const currentFormType = location.pathname.split("/")[2];
  const currentRecordId = location.pathname.split("/")[4];
  const general = useSelector((state) => state.general);
  const [userName, setUserName] = useState("Nome Cognome");
  const [userImage, setUserImage] = useState(
    "https://placehold.co/600x400/EEE/31343C"
  );

  const { isLoaded, loadError } = useGoogleMaps();



  const center = {
    lat: formData.latitude ? parseFloat(formData.latitude) : -34.397,
    lng: formData.longitude ? parseFloat(formData.longitude) : 150.644
  };
  const [userLocation, setUserLocation] = useState({
    city: "Città",
    address: "Indirizzo",
  });

  const containerStyle = {
    width: '100%',
    height: '200px'
  };

  //chiave della form, andrebbe settato quando apri modali con form dinamiche vista la situazione secondo me
  const [keyModal, setKeyModal] = useState(null);

  const fetchFormData = async () => {
    try {
      const token = localStorage.getItem("token");
      const headers = { Authorization: `Bearer ${token}` };
      const endpoint = process.env.REACT_APP_APP_ENDPOINT;

      const getRecord = axios.get(
        `${endpoint}api/auth/getRecord/${currentFormType}/${currentRecordId}`,
        { headers }
      );

      const [recordResponse] = await Promise.all([getRecord]);

      setFormData(recordResponse.data);
      setFormValues(recordResponse.data);
    } catch (error) {
      const errorMessage = error.response
        ? error.response.data
        : "Errore durante il recupero dei dati.";
      toast.error(errorMessage);
      setIsError(true);
    }
  };

  useEffect(() => {
    if (currentFormType && currentRecordId) {
      fetchFormData().then(() => setLoading(false));
    }
  }, [currentFormType, currentRecordId]);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [currentObjectType, setCurrentObjectType] = useState(null);
  const [currentRelationField, setCurrentRelationField] = useState(null);

  const handleCloseModal = () => {
    setOpenModals((prev) => ({ ...prev, [keyModal]: false }));
    setIsModalOpen(false);
  };

  const handleDeleteAction = () => {
    // Logica per gestire l'azione "Elimina"
    console.log("Azione di eliminazione eseguita");
    // Aggiungi qui la logica di eliminazione
  };

  const [selectedTab, setSelectedTab] = useState("1");

  // Gestisce il cambiamento dei tab
  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleCloseDialog = () => {
    setIsModalOpen(false);
  };

  const extractMasterDetailData = (response) => {
    const masterDetailData = response?.customRelations?.MasterDetail || {};
    return masterDetailData;
  };

  const MasterDetailModal = ({
    objectName,
    fieldName,
    currentRecordId,
    isOpen,
    onClose,
    relationId,
  }) => {
    useEffect(() => { }, [objectName, fieldName, currentRecordId]);

    return (
      <Drawer
        anchor="right" // Questo posiziona il drawer a destra
        open={isOpen}
        onClose={onClose}
        sx={{
          zIndex: 1300, // Aumenta questo valore secondo necessità

          "& .MuiDrawer-paper": {
            maxWidth: "600px", // Applica la larghezza massima al drawer
            padding: 2,
            width: "100%", // Usa l'intera larghezza disponibile fino a un massimo di 400px
            zIndex: 1300, // Aumenta questo valore secondo necessità
          },
        }}
      >
        <div style={{ width: "auto" }}>
          <div style={{ paddingLeft: 20, paddingTop: 12 }}>
            <Typography variant="h5" sx={{}}>
              Crea un nuovo{" "}
              {objectName.charAt(0).toUpperCase() + objectName.slice(1)}
            </Typography>
          </div>
          {/* Puoi specificare una larghezza fissa qui se necessario */}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div style={{ padding: 0 }}>
            {" "}
            {/* Aggiungi padding per il contenuto interno */}
            {/* Il componente DynamicFormComponentModal rimane invariato */}
            <DynamicFormComponentModal
              heading={objectName}
              onCloseModal={onClose} // Assicurati che handleCloseModal sia definito correttamente o utilizza direttamente onClose
              objectType={objectName}
              onClose={onClose}
              updateData={fetchFormData}
              prefilledValues={{
                [fieldName]: currentRecordId,
                related: `${relationId}:${currentRecordId}`,
                owner_id: general.userId, // Assicura che general.userId sia definito correttamente
              }}
              hiddenFields={{ [fieldName]: true, related: true }}
            />
          </div>
        </div>
      </Drawer>
    );
  };

  const [openModals, setOpenModals] = useState({});

  const toggleModal = (key, isOpen, fieldName, objectName) => {
    setCurrentObjectType(objectName);
    setCurrentRelationField(fieldName);

    setOpenModals((prev) => ({ ...prev, [key]: isOpen }));
  };

  const renderMasterDetailTables = (masterDetailData) => {
    return Object.keys(masterDetailData).map((key) => {
      const masterDetails = masterDetailData[key];
      const isModalOpen = openModals[key];

      return (
        <Accordion key={key} defaultExpanded={true}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
            aria-controls={`${key}-content`}
            id={`${key}-header`}
            sx={{
              padding: 0,
              '& .MuiAccordionSummary-content': {
                margin: 0,  // Rimuove i margini del contenuto
                padding: 0,
              },
              color: "white",
              backgroundColor: "#003e57",
              borderTopLeftRadius: 20,
              borderBottomLeftRadius: 20,
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              sx={{ padding: "0px 16px", margin: 0 }}
            >
              <Typography sx={{ fontWeight: "bold", color: "white", fontSize: 20 }}>
                {`${key} (${masterDetails.relatedRecords.length})`}
              </Typography>
              <Button
                variant="contained"
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setKeyModal(key);
                  toggleModal(
                    key,
                    true,
                    masterDetails.fieldName,
                    masterDetails.objectName
                  );
                }}
                onFocus={(event) => event.stopPropagation()}
                sx={{
                  padding: "4px 8px",
                  backgroundColor: "white",
                  color: "green",
                  fontWeight: "bold",
                  fontSize: "0.7rem",
                  minWidth: "35p",
                  '&:hover': {
                    backgroundColor: "#f0f0f0",
                  }
                }}
              >
                Aggiungi +
              </Button>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 0 }}>
            {masterDetails.objectName === "file" && (
              <FileMasterDetail
                masterDetails={masterDetails}
                fetchFormData={fetchFormData}
              />
            )}
            {masterDetails.objectName !== "file" && (
              <MasterDetailTable
                masterDetails={masterDetails}
                objectName={masterDetails.objectName}
                fetchFormData={fetchFormData}
              />
            )}

            <CardActions sx={{ justifyContent: "center", borderTop: "1px dotted lightgrey" }}>
              <RouterLink
                to={`/list/${masterDetails.objectName}/${currentRecordId}/related/${masterDetails.relationId}`}
                style={{ textDecoration: "none" }}
              >
                <Typography variant="body2" fontSize={14}>Visualizza tutti</Typography>
              </RouterLink>
            </CardActions>
          </AccordionDetails>
          <MasterDetailModal
            objectName={masterDetails.objectName}
            fieldName={masterDetails.fieldName}
            relationId={masterDetails.relationId}
            currentRecordId={currentRecordId}
            isOpen={isModalOpen}
            onClose={() => toggleModal(key, false)}
          />
        </Accordion>
      );
    });
  };


  const renderMasterDetailButtons = (masterDetailDataa) => {
    return Object.keys(masterDetailData).map((key) => {
      const masterDetails = masterDetailData[key];
      const isModalOpen = openModals[key];

      return (
        <>
          <Tooltip
            title="Aggiungi descrizione tooltip qui"
            placement="top"
            style={{ marginRight: "10px" }}
          >
            <Button
              onClick={(event) => {
                event.preventDefault();
                setKeyModal(key);
                toggleModal(
                  key,
                  true,
                  masterDetails.fieldName,
                  masterDetails.objectName
                );
              }}
              style={{
                color: "black",
                backgroundColor: "white",
                padding: "6px 10px",
                textAlign: "center",
                marginRight: 4,
                fontSize: "0.8rem"
              }}
            >
              Nuovo {masterDetails.objectName}
            </Button>

          </Tooltip>

          <MasterDetailModal
            objectName={masterDetails.objectName}
            fieldName={masterDetails.fieldName}
            relationId={masterDetails.relationId}
            currentRecordId={currentRecordId}
            updateData={fetchFormData}
            isOpen={isModalOpen}
            onClose={() => toggleModal(key, false)}
          />
        </>
      );
    });
  };

  const renderMasterDetailCenters = (masterDetailData) => {
    return Object.keys(masterDetailData).map((key) => {
      const masterDetails = masterDetailData[key];
      const isModalOpen = openModals[key];

      let gridContent;
      switch (key.toLowerCase()) {
        case "note":
          gridContent = <CommentSection comments={masterDetails} />;
          break;
        case "email":
          gridContent = <EmailButton emails={masterDetails} />;
          break;
        case "scadenze":
          gridContent = (
            <Calendar
              relatedRecordId={currentRecordId}
              events={masterDetails}
              customOptions={{ defaultMode: "month" }}
              customToolbarProps={{
                showSearchBar: false,
                showDatePicker: true,
              }}
            />
          );
          break;
        default:
          gridContent = false;
          break;
      }

      return gridContent ? (
        <Grid key={key} item xs={12} sm={12}>
          {gridContent}
        </Grid>
      ) : null;
    });
  };

  const extractLookupDetails = (response) => {
    const lookupDetails = [];
    const details = response?.customRelations?.Lookup || {};

    Object.keys(details).forEach((key) => {
      const detail = details[key];
      lookupDetails.push({ ...detail, relationName: key });
    });

    return lookupDetails;
  };

  const renderLookupDetails = (response) => {
    const lookupDetails = extractLookupDetails(response);

    return lookupDetails.map((detail, index) => (
      <div key={index}>
        <CardHeader
          sx={{
            padding: 0,
            backgroundColor: "#e2e2e2",
            borderTopLeftRadius: 20,
            borderBottomLeftRadius: 20,
            marginBottom: 1,
            color: "white",
            backgroundColor: "#10a6e9",
          }}
          avatar={
            <Avatar>
              <AccountCircleIcon />
            </Avatar>
          }
          title={detail.relationName}
          titleTypographyProps={{ variant: "h6" }}
        />
        <Card sx={{ borderRadius: 0, marginBottom: "20px", padding: 2 }}>
          <CardContent sx={{ padding: 0 }}>
            {detail.relatedRecords.length > 0 ? (
              detail.relatedRecords.map((record, recordIndex) => (
                <div key={recordIndex}>
                  {Object.entries(record.relatedValues).map(
                    ([fieldKey, fieldValue]) => {
                      if (
                        (!Array.isArray(fieldValue) &&
                          fieldKey != "customRelations" &&
                          fieldKey != "Creato il" &&
                          fieldKey != "Related To") && fieldValue
                      ) {
                        return (
                          <Typography key={fieldKey}>
                            {`${fieldKey}: ${fieldValue}`}
                          </Typography>
                        );
                      }
                      return null;
                    }
                  )}
                </div>
              ))
            ) : (
              <Typography sx={{ margin: "50px" }}>
                <Typography sx={{ m: 5, fontSize: 16, color: "gray", textAlign: "center" }}>Non sono presenti {detail.relationName}.</Typography>
              </Typography>
            )}
          </CardContent>
        </Card>
      </div>
    ));
  };

  const masterDetailData = extractMasterDetailData(formData);

  const handleImageChange = () => {
    setUserImage(
      userImage === "https://placehold.co/600x400/EEE/31343C"
        ? "https://placehold.co/600x400/EEE/31343C"
        : "https://placehold.co/600x400/EEE/31343C"
    );
  };

  return (
    <>
      <Grid container spacing={1} sx={{ marginTop: "0px", padding: 0 }}>

        <Grid item md={6}>
          <Grid container>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                maxHeight: "calc(100vh - 40px)",
                paddingRight: "8px",
                background: "#fff",
              }}
            >
              {loading ? (
                <Skeleton variant="rectangular" height={400} animation="wave" />
              ) : (
                <>
                  <CardContent sx={{
                    background: "#003e57",
                    color: "white",
                    borderRadius: "10px"
                  }}>

                    <Grid container spacing={0} alignItems="center">

                      <Grid item xs>
                        <Typography
                          variant="h5"
                          component="div"
                          gutterBottom
                          sx={{
                            fontWeight: 'bold', // Imposta il testo in grassetto
                            textTransform: 'uppercase', // Trasforma il testo in maiuscolo
                            fontSize: 30,
                            color: "#ffcb00",
                            marginBottom: 0
                          }}
                        >
                          {formData["title"]}
                        </Typography>
                        <Typography variant="subtitle2" sx={{

                          fontSize: 14,
                          color: "#fff",
                          marginBottom: 4
                        }}>
                          {formData["address"]}
                        </Typography>

                        <ButtonGroup
                          variant="text"
                          aria-label="CRM button group"
                          style={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "left",
                            marginTop: 4,
                            padding: 0,
                          }}
                        >
                          {renderMasterDetailButtons(masterDetailData)}
                          {/* Aggiungi qui altre funzionalità con i relativi pulsanti e icone */}
                        </ButtonGroup>
                      </Grid>


                    </Grid>
                  </CardContent>

                  {isLoaded && formData.latitude && (
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={15}
                    >
                      <Marker position={center} />
                    </GoogleMap>
                  )}



                  <Card
                    sx={{ borderRadius: 0, background: "#fff", boxShadow: "none" }}
                  >
                    <CardContent sx={{ padding: "2px" }}>
                      {/* I bottoni sopra i tab */}

                      {/* TabContext con Tabs */}
                      <Grid container spacing={2}>
                        {renderMasterDetailCenters(masterDetailData)}
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              )}
            </Grid>


            <Grid
              item
              xs={12}
              md={12}
              sx={{
                maxHeight: "calc(100vh - 40px)",
                paddingRight: "8px",
              }}
            >
              {loading ? (
                <Skeleton variant="rectangular" height={400} animation="wave" />
              ) : (
                <SimpleDynamicForm isShow="true" />
              )}
            </Grid>
          </Grid>
        </Grid>


        <Grid
          item
          xs={12}
          md={6}
          sx={{
            maxHeight: "calc(100vh - 40px)",
            paddingRight: "8px",
          }}
        >
          {loading ? (
            <Skeleton variant="rectangular" height={400} animation="wave" />
          ) : (
            <>
              {renderLookupDetails(formData)}

              {/*  <CardHeader
                sx={{
                  padding: 0,
                  backgroundColor: "#e2e2e2",
                  borderTopLeftRadius: 20,
                  borderBottomLeftRadius: 20,
                  marginBottom: 1,
                  color: "white",
                  backgroundColor: "#10a6e9",
                }}
                avatar={
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                }
                title="Altri Collegamenti"
                titleTypographyProps={{ variant: "h6" }}
              />*/}
              {renderMasterDetailTables(masterDetailData)}
            </>
          )}
        </Grid>
      </Grid >
    </>
  );
};

export default Show;
