import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumbs as MUIBreadcrumbs, Typography, Box, Button } from '@mui/material';
import { thunks } from "../thunks/general";

import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const Breadcrumbs = () => {
    const location = useLocation();
    const pathnames = location.pathname
        .split('/')
        .filter((x) => x && !['list', 'adminList', 'show', 'newly', 'true', 'status', 'filter'].includes(x));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const general = useSelector((state) => state.general);

    const breadcrumbItems = [
        { label: 'Home', path: '/' },
        ...pathnames.map((value, index) => {
            // Decodifica il valore del segmento di percorso
            const decodedValue = decodeURIComponent(value);
            const pathSegment = decodedValue === 'adminList' ? 'list' : decodedValue;
            const path = `/list/${pathnames.slice(0, index + 1).map(segment => decodeURIComponent(segment) === 'adminList' ? 'list' : decodeURIComponent(segment)).join('/')}`;
            return { label: pathSegment.charAt(0).toUpperCase() + pathSegment.slice(1), path };
        })
    ];

    const handleLogout = () => {
        localStorage.removeItem('token');
        dispatch(thunks.resetState());
        navigate('/');
    };

    return (
        <Box
            sx={{
                backgroundColor: 'white',
                padding: '16px',
                borderRadius: '8px',
                marginBottom: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <MUIBreadcrumbs aria-label="breadcrumb" sx={{ fontSize: '1rem', letterSpacing: '0.5px' }}>
                {breadcrumbItems.map((item, index) =>
                    index !== breadcrumbItems.length - 1 ? (
                        <Link
                            key={index}
                            to={item.path}
                            style={{ textDecoration: 'none', color: '#0a74da', paddingRight: '8px' }}
                        >
                            {item.label}
                        </Link>
                    ) : (
                        <Typography
                            key={index}
                            color="text.primary"
                            sx={{ fontWeight: 'bold', paddingLeft: '8px' }}
                        >
                            {item.label}
                        </Typography>
                    )
                )}
            </MUIBreadcrumbs>

            <Button
                variant="contained"
                onClick={handleLogout}
                sx={{
                    boxShadow: 'none',
                    maxWidth: '100px',
                    fontSize: '0.8rem',
                    padding: '6px 12px',
                    backgroundColor: '#0a74da',
                    color: 'white',
                    '&:hover': {
                        backgroundColor: '#005bb5',
                    },
                    display: 'flex',
                    alignItems: 'center',
                }}
                startIcon={<LogoutIcon sx={{ color: 'white' }} />}
            >
                LogOut
            </Button>
        </Box>
    );
};

export default Breadcrumbs;
