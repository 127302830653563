import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { Grid, Card, CardContent, Button, MenuItem, Select, FormControl, InputLabel, Toolbar, CircularProgress, CardActionArea, Tooltip, Alert, TextField } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import AddIcon from '@mui/icons-material/Add';

function DashboardEmail() {
    const [emails, setEmails] = useState([]);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [page, setPage] = useState(1);
    const [emailsPerPage, setEmailsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [totalEmails, setTotalEmails] = useState(0);
    const [loadingEmails, setLoadingEmails] = useState(false);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [googleAccounts, setGoogleAccounts] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState('all');
    const [thread, setThread] = useState([]);
    const [successMessage, setSuccessMessage] = useState(null);
    const [replyContent, setReplyContent] = useState('');
    const [selectedSenderAccount, setSelectedSenderAccount] = useState('');

    useEffect(() => {
        const message = sessionStorage.getItem('success');
        if (message) {
            setSuccessMessage(message);
            sessionStorage.removeItem('success');
        }
    }, []);

    const fetchEmails = async (currentPage, limit, accountId = null) => {
        setLoadingEmails(true);
        try {
            const response = await axios.get(`https://erpbackend.formalav.com/public/api/emails`, {
                params: {
                    page: currentPage,
                    perPage: limit,
                    google_account_id: accountId !== 'all' ? accountId : null
                },
                headers: {
                    Authorization: `Bearer YOUR_ACCESS_TOKEN` // Sostituisci con il tuo token
                }
            });
            setEmails(response.data.data);
            setTotalPages(response.data.last_page);
            setTotalEmails(response.data.total);
        } catch (error) {
            console.error('Errore nel recuperare le email:', error);
        } finally {
            setLoadingEmails(false);
        }
    };

    const fetchGoogleAccounts = async () => {
        try {
            const response = await axios.get(`https://erpbackend.formalav.com/public/api/google-accounts`, {
                headers: {
                    Authorization: `Bearer YOUR_ACCESS_TOKEN` // Sostituisci con il tuo token
                }
            });
            setGoogleAccounts(response.data);
        } catch (error) {
            console.error('Errore nel recuperare gli account Google:', error);
        }
    };

    const fetchEmailDetails = async (emailId, accountId) => {
        setLoadingDetails(true);
        try {
            const response = await axios.get(`https://erpbackend.formalav.com/public/api/emails/${emailId}`, {
                params: {
                    google_account_id: accountId
                },
                headers: {
                    Authorization: `Bearer YOUR_ACCESS_TOKEN` // Sostituisci con il tuo token
                }
            });
            setThread(response.data.messages);
            setSelectedEmail(response.data);

        } catch (error) {
            console.error('Errore nel recuperare i dettagli dell\'email:', error);
        } finally {
            setLoadingDetails(false);
        }
    };

    const handleReply = async () => {

        try {
            const response = await axios.post('https://erpbackend.formalav.com/public/api/reply-email', {
                google_account_id: selectedSenderAccount,
                body: replyContent,
                thread_id: selectedEmail.mainMessage.id // Assicurati che `threadId` sia presente in `selectedEmail`
            }, {
                headers: {
                    Authorization: `Bearer YOUR_ACCESS_TOKEN`
                }
            });

            if (response.status === 200) {
                setReplyContent(''); // Resetta il contenuto della risposta
                alert('Risposta inviata con successo');
            }
        } catch (error) {
            console.error('Errore nell\'invio della risposta:', error);
            alert('Errore nell\'invio della risposta');
        }
    };

    useEffect(() => {
        fetchGoogleAccounts();
    }, []);

    useEffect(() => {
        fetchEmails(page, emailsPerPage, selectedAccount);
    }, [page, emailsPerPage, selectedAccount]);

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleEmailsPerPageChange = (event) => {
        setEmailsPerPage(event.target.value);
        setPage(1); // Reset alla prima pagina quando si cambia il numero di email per pagina
    };

    const handleAccountChange = (event) => {
        setSelectedAccount(event.target.value);
        setPage(1); // Reset alla prima pagina quando si cambia account
    };

    const handleEmailClick = (email) => {
        fetchEmailDetails(email.id, selectedAccount);
    };


    function decodeBase64(input) {
        try {
            return atob(input.replace(/-/g, '+').replace(/_/g, '/'));
        } catch (e) {
            console.error('Errore nella decodifica Base64:', e);
            return input;
        }
    }

    return (
        <>
            <div>
                {successMessage && (
                    <Alert severity="success" onClose={() => setSuccessMessage(null)}>
                        {successMessage}
                    </Alert>
                )}
            </div>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <Toolbar>
                        <FormControl fullWidth variant="outlined" sx={{ mb: 2, maxWidth: "400px", mt: 2, display: 'inline-flex' }}>
                            <InputLabel id="select-account-label">Filtra per casella</InputLabel>
                            <Select
                                labelId="select-account-label"
                                value={selectedAccount}
                                onChange={handleAccountChange}
                                label="Filtra per casella"
                            >
                                <MenuItem value="all" selected>Tutte le caselle</MenuItem>
                                {googleAccounts.map((account) => (
                                    <MenuItem key={account.id} value={account.id}>
                                        {account.email}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            sx={{ mb: 2, ml: 2, mt: 2, height: 'fit-content' }}
                            onClick={() => window.location.href = 'https://erpbackend.formalav.com/public/api/auth/google'}
                        >
                            Aggiungi
                        </Button>


                    </Toolbar>
                </Grid>
                <Grid item xs={4} >
                    <Card sx={{ m: 2 }}>
                        <CardContent>
                            {loadingEmails ? (
                                <div style={{ textAlign: 'center', padding: '20px' }}>
                                    <CircularProgress />
                                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                        Recupero le email...
                                    </Typography>
                                </div>
                            ) : (
                                <>
                                    {emails.map((email) => (
                                        <CardActionArea
                                            key={email.id}
                                            onClick={() => handleEmailClick(email)}
                                            sx={{
                                                mb: 1,
                                                p: 1,
                                                bgcolor: email.isUnread ? 'rgba(0, 0, 0, 0.04)' : 'inherit',
                                                fontWeight: email.isUnread ? 'bold' : 'normal'
                                            }}
                                        >
                                            <Tooltip title={email.subject} arrow>
                                                <Typography
                                                    variant="body1"
                                                    noWrap
                                                    sx={{ fontSize: '16px', fontWeight: email.isUnread ? 'bold' : 'normal' }}
                                                >
                                                    {email.subject}
                                                </Typography>
                                            </Tooltip>
                                            <Typography variant="body2" color="textSecondary" noWrap>
                                                Da: {email.from}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" noWrap>
                                                A: {email.to ? email.to : 'Nessun destinatario'}
                                            </Typography>

                                        </CardActionArea>
                                    ))}
                                </>
                            )}

                            {loadingEmails ? (
                                <></>
                            ) : (
                                <>
                                    <Pagination
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                        sx={{ mt: 2 }}
                                    />
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={8}>
                    <Card sx={{ m: 1 }}>
                        <CardContent>
                            {loadingDetails ? (
                                <div style={{ textAlign: 'center', padding: '20px' }}>
                                    <CircularProgress />
                                    <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
                                        Carico i dettagli...
                                    </Typography>
                                </div>
                            ) : selectedEmail ? (

                                <>
                                    {/* Visualizzazione della mail principale */}
                                    {selectedEmail && selectedEmail.mainMessage && (
                                        <>
                                            <Typography variant="h5">{selectedEmail.mainMessage.subject}</Typography>
                                            <Typography variant="subtitle1">Da: {selectedEmail.mainMessage.from}</Typography>
                                            <Typography variant="subtitle1">A: {selectedEmail.mainMessage.to}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {selectedEmail.mainMessage.date ? new Date(selectedEmail.mainMessage.date).toLocaleString() : ''}
                                            </Typography>
                                            <hr />
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: selectedEmail.mainMessage.body
                                                        ? decodeBase64(selectedEmail.mainMessage.body)
                                                        : 'Nessun contenuto disponibile'
                                                }}
                                                style={{ marginTop: '10px', marginBottom: '20px' }}
                                            />
                                        </>
                                    )}

                                    {/* Visualizzazione del thread delle risposte */}
                                    <hr />
                                    <Typography variant="h6">Risposte:</Typography>
                                    {selectedEmail && selectedEmail.thread && selectedEmail.thread.length > 0 ? (
                                        selectedEmail.thread.map((message) => (
                                            <div key={message.id} style={{ marginBottom: '20px' }}>
                                                <Typography variant="subtitle2">
                                                    Da: {message.from} - A: {message.to}
                                                </Typography>
                                                <Typography variant="body2" color="textSecondary">
                                                    {message.date ? new Date(message.date).toLocaleString() : ''}
                                                </Typography>
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: message.body
                                                            ? decodeBase64(message.body)
                                                            : 'Nessun contenuto disponibile'
                                                    }}
                                                    style={{ marginTop: '10px', marginBottom: '20px' }}
                                                />
                                                <hr />
                                            </div>
                                        ))
                                    ) : (
                                        <Typography variant="body2" color="textSecondary">
                                            Nessuna risposta disponibile.
                                        </Typography>
                                    )}

                                    {/* Campo di risposta */}

                                    <TextField
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        variant="outlined"
                                        label="Rispondi"
                                        value={replyContent}
                                        onChange={(e) => setReplyContent(e.target.value)}
                                        sx={{ mt: 2 }}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleReply}
                                        sx={{ mt: 2 }}
                                    >
                                        Invia Risposta
                                    </Button>
                                </>


                            ) : (
                                <Typography variant="h6" color="textSecondary">
                                    Seleziona un'email per leggerla.
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid >
        </>
    );
}

export default DashboardEmail;
